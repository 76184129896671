import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Box, useTheme, Button, Grid, ImageList, ImageListItemBar, Pagination, IconButton } from "@mui/material";
import { tokens } from "../../../theme";
import {api} from '../../../config/axios';
import { marcarURL } from '../../../config/functions';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';

//COMPONENTES
import Header from '../../../components/Header';
import CrearVestido from './modales/CrearVestido';
import EditarVestido from './modales/EditarVestido';
import FormularioFiltrado from './modales/FormularioFiltrado';

const LazyImageListItem = lazy(() => import("@mui/material/ImageListItem"));

const Vestidos = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // SEGMENTO PARA BUSCAR CAJAS
    // ===============================================

    useEffect(() => {

        window.scrollTo(0, 0);

        buscarVestidos();

        marcarURL('vestidos');

    },[]);

    // ===============================================
    // FUNCION PARA BUSCAR TALLAS
    // ===============================================

    const [vestidos, setVestidos] = useState([]);

    const buscarVestidos = async () => {

        const response = await api.get('vestidos');

        if(response.data !== "Sin Resultados" && response.status === 200){

            let data = response.data;

            data.map((op, index) => {

                const finalPath = `https://vestidos.queendress.com.mx/qb${op.ruta_foto}`;

                data[index]['ruta_foto_view'] = finalPath;

            })

            setVestidos(data);

        }

    }

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREAR
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {
        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL EDITAR
    // ===============================================

    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const handleOpenModalEdit = () => {
        setIsOpenEdit(true);
    };
    
    const handleCloseModalEdit = () => {
        setIsOpenEdit(false);
    };

    // ===============================================
    // ABRIR MODAL PARA EDITAR 
    // ===============================================

    const [formValues, setFormValues] = useState([]);

    const handleEdit = (values) => {

        setFormValues(values);

        handleOpenModalEdit();

    }

    // ===============================================
    // CONFIGURACION DEL PAGINADO
    // ===============================================

    const itemsPerPage = 8; // Número de imágenes por página
    const [page, setPage] = useState(1);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedVestidos = vestidos.slice(startIndex, endIndex);

    // ===============================================

    return (

        <Box m="20px">

            <Header title="Vestidos" subtitle="Catalago de Vestidos"/>

            <Grid container>

                {/* BOTON PARA ABRIR EL MODAL */}

                <Grid item md={2} xs={12} marginBottom={3}>

                    <Button variant="contained" 
                        sx={{
                                fontWeight:"bold",
                                backgroundColor:colors.orangeAccent[100]
                        }} 
                        startIcon={<AddCircleIcon />}
                        onClick={handleOpenModal}
                    >
                        Agregar Vestido
                    </Button>
                    
                </Grid>   

                {/* SECCION PARA FILTRAR VESTIDOS */}

                <Grid 
                    item 
                    md={10}
                    xs={12} 
                    marginBottom={2}
                >

                    <FormularioFiltrado
                        filtrado={setVestidos}
                        refresh={buscarVestidos}
                    />
                    
                </Grid> 

                {/* PAGINADO */}

                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'end'}}>

                    <Pagination 
                        count={Math.ceil(vestidos.length / itemsPerPage)} 
                        page={page}
                        onChange={handleChangePage}
                        size="large"
                    />

                </Grid>

                {/* GALERIA DE IMAGENES */}

                <Grid item xs={12}>

                    <ImageList sx={{ width: '100%' }} cols={4}>

                    {
                        displayedVestidos.map((item, index) => (

                            <Suspense key={index} fallback={<div>Cargando...</div>}>

                                <LazyImageListItem>

                                    <img
                                        src={item.ruta_foto_view}
                                        alt={item.id}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={item.precio}
                                        subtitle={<><span><b>Color</b>: {item.colorDescripcion}</span> <span><b>Talla</b>: {item.tallaDescripcion}</span></>}
                                        position="below"
                                        actionIcon={
                                            <IconButton 
                                                onClick={() => handleEdit(item)}
                                            >
                                              <EditIcon/>
                                            </IconButton>
                                          }
                                    />
                                    
                                </LazyImageListItem>

                            </Suspense>

                        ))
                    }

                    </ImageList>

                </Grid>

                {/* PAGINADO */}

                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'end'}}>

                    <Pagination 
                        count={Math.ceil(vestidos.length / itemsPerPage)} 
                        page={page}
                        onChange={handleChangePage}
                        size="large"
                    />

                </Grid>

            {/* MODAL PARA CREAR VESTIDOS */}

            <CrearVestido
                open={isOpen} 
                onClose={handleCloseModal} 
                refresh={buscarVestidos}
            />

            {/* MODAL PARA EDITAR VESTIDOS */}

            <EditarVestido
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                refresh={buscarVestidos}
                data={formValues}
            />

        </Grid>

        </Box>

    )

}

export default Vestidos