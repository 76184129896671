import React, { useEffect, useState } from 'react';
import { IconButton, Grid, MenuItem} from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { api } from '../../../../config/axios';

//ICONOS
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

//COMPONENTES
import MySelect from '../../../../components/inputs/MySelect';

const FormularioFiltrado = ({filtrado, refresh}) => {

    // ===============================================
    // FUNCION PARA BUSCAR COLORES
    // ===============================================

    const [colores, setColores] = useState([]);

    const buscarColores = async () => {

        const response = await api.get('colores');

        if(response.data !== "Sin Resultados" && response.status === 200){

            setColores(response.data);

        }

    }

    // ===============================================
    // FUNCION PARA BUSCAR TALLAS
    // ===============================================

    const [tallas, setTallas] = useState([]);

    const buscarTallas = async () => {

        const response = await api.get('tallas');

        if(response.data !== "Sin Resultados" && response.status === 200){

            setTallas(response.data);

        }

    }

    // ===============================================
    // FUNCION PARA BUSCAR VESTIDOS FILTRADOS
    // ===============================================

    const handleSubmit = async (values) => {

        let data;

        // FILTRAR VESTIDOS POR COLOR

        if(values.opcion === 'Color'){

            const response = await api.get(`vestidos/color/${values.valor}`);

            if(response.data !== "Sin Resultados" && response.status === 200){

                data = response.data;

            }

        }

        // FILTRAR VESTIDOS POR TALLA

        if(values.opcion === 'Talla'){

            const response = await api.get(`vestidos/talla/${values.valor}`);

            if(response.data !== "Sin Resultados" && response.status === 200){

                data = response.data;

            }

        }

        data.map((op, index) => {

            const finalPath = `https://vestidos.queendress.com.mx/qb${op.ruta_foto}`;

            data[index]['ruta_foto_view'] = finalPath;

        })

        filtrado(data);

    } 

    // ===============================================
    // LLAMAR A TODAS LAS FUNCIONES
    // ===============================================

    useEffect(() => {

        buscarColores();

        buscarTallas();

    },[])

    return (

        <Formik
            initialValues={{ 
                opcion: '',
                valor: '',
            }}
            validationSchema={Yup.object().shape({
                opcion: Yup.string().required('Campo Requerido'),
                valor: Yup.string().required('Campo Requerido'),
            })}
            onSubmit={handleSubmit}
        >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (

            <Form>

                <Grid 
                    container 
                    spacing={2}                     
                    sx={{
                        display: 'flex', 
                        justifyContent: 'end',
                    }}
                >

                    <Grid item md={2} xs={6}>

                        <MySelect
                            label="Filtrar Por"
                            name="opcion"
                        >
                            <MenuItem value={'Color'}>
                                Color
                            </MenuItem>
                            <MenuItem value={'Talla'}>
                                Talla
                            </MenuItem>
                        </MySelect>

                    </Grid>

                    {/* VALOR POR FILTRAR */}

                    <Grid item md={2} xs={6}>

                        <MySelect
                            label=""
                            name="valor"
                        >
                        {
                            values.opcion === 'Color'
                            ? 
                            (
                                colores.map((op, index) => (

                                    <MenuItem 
                                        value={op.id} 
                                        key={index}
                                    >
                                        {op.color}
                                    </MenuItem>
        
                                ))
                            )
                            :
                            (
                                tallas.map((op, index) => (

                                    <MenuItem 
                                        value={op.id} 
                                        key={index}
                                    >
                                        {op.talla}
                                    </MenuItem>
        
                                ))
                            )
                            
                        }
                        </MySelect>

                    </Grid>

                    {/* BOTON PARA BUSCAR VESTIDOS */}

                    <Grid item md={2} xs={12}>

                        <IconButton 
                            type="submit" 
                            sx={{
                                backgroundColor: 'rgb(27, 167, 255)', 
                                marginRight: '10px'
                            }} 
                        >
                            <SearchIcon />
                        </IconButton>

                        <IconButton
                            onClick={() => refresh()}
                            sx={{
                                backgroundColor: 'rgb(255, 165, 0)', 
                            }} 
                        >
                            <RefreshIcon />
                        </IconButton>

                    </Grid>

                </Grid>

            </Form>

        )}
        </Formik>

    )

}

export default FormularioFiltrado