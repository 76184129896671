import React, { useEffect, useState } from 'react';
import { Button, Modal, Paper, Typography, Box, Grid, Container, Divider, Alert, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTheme } from "@mui/material";
import { tokens } from '../../theme';
import useMediaQuery from "@mui/material/useMediaQuery";
import Swal from 'sweetalert2';
import { styled } from '@mui/system'
import { api } from '../../config/axios';

//ICONOS
import PaidIcon from '@mui/icons-material/Paid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrintIcon from '@mui/icons-material/Print';
import AssignmentIcon from '@mui/icons-material/Assignment';

// COMPONENTES
import TextInput from '../../components/inputs/TextInput';
import MyPhoneInput from '../../components/inputs/MyPhoneInput';
import MyMoneyInput from '../../components/inputs/MyMoneyInput';

const ModalTerminarRenta = ({ open, onClose, vestidos, total}) => {

    console.log(vestidos)

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // VALIDAR SI SE ESTA VIENDO DESDE UN DISPOSITVO MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // ESTADO PARA MOSTRAR LA ALERTA
    // ===============================================

    const [warning, setWarning] = useState({

        show: false,
        message: ''

    })

    // ===============================================
    // ESTADO PARA MOSTRAR LA ALERTA
    // ===============================================

    useEffect(() => {

        setWarning({
            show: false,
            message: ''
        })

    },[]);

    // ===============================================
    // FUNCION PARA SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        // VALIDAMOS SI EL VESTIDO YA ESTA PAGADO

        const totalEfectivo = values.monto_efectivo !== '' ? Number(values.monto_efectivo.split('$')[1]) : 0;
        const totalTarjeta = values.monto_tarjeta !== '' ? Number(values.monto_tarjeta.split('$')[1]) : 0;

        const totalSobrante = total - (totalEfectivo + totalTarjeta);

        // VALIDAR SI LAS SUMAS EXCEDEN EL TOTAL

        if((totalEfectivo + totalTarjeta) > total){

            setWarning({
                show: true,
                message: 'Los montos exceden el total!'
            })

            return;

        }

        // VALIDAR SI LOS MONTOS VIENEN VACIOS

        if(totalEfectivo === 0 && totalTarjeta === 0){

            setWarning({
                show: true,
                message: 'Ingresa Monto de Pago!'
            })

            return;

        }

        // AGRUPAMOS LOS VALORES PARA HACER EL SUBMIT

        const datosOrdenRenta = {
            nombre: values.nombre,
            telefono: values.telefono,
            vestidos: vestidos,
            monto_total: total,
            monto_efectivo: values.monto_efectivo,
            monto_tarjeta: values.monto_tarjeta,
            pagado: totalSobrante === 0 ? 1 : 0
        }

        // SECCION PARA HACER SUBMIT

        const url = 'orden/renta/crear';

        api.post(url, datosOrdenRenta)
        .then((response) => {

            if(response.data.success === true){

                setShowFormulario(false)

                console.log(response)

                // onClose();

                // refresh();

                // Swal.fire({
                //     icon: 'success',
                //     title: response.data.message,
                // })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================
    // ESTADO PARA CONTROLAR FORMULARIO Y VISTA FINAL
    // ===============================================

    const [showFormulario, setShowFormulario] = useState(true);

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={{ 
                    nombre: '',
                    telefono: '',
                    monto_efectivo: '',
                    monto_tarjeta: ''
                }}
                validationSchema={Yup.object().shape({
                    nombre: Yup.string().required('Campo Requerido'),
                    telefono: Yup.string().required('Campo Requerido'),
                })}
                onSubmit={handleSubmit}
            >
            {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (

                <Paper
                    sx={{ 
                        width: isNonMobile ? `50%` : '90%', 
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    {/* HEADER DEL MODAL */}

                    <Typography variant="h3" align="center" marginBottom={3}>
                        {/* Renta Vestido - ${total.toFixed(2)} */}
                        Terminar Transaccion
                    </Typography>

                    <Form>

                        {/* CUERPO DEL MODAL */}

                            {/* SECCION DEL FORMULARIO */}

                            <ScrollableContainer>

                                <Grid container spacing={2} marginBottom={3}>

                                    {

                                        //  SECCION PARA MOSTRAR FORMULARIO

                                        showFormulario ? (

                                            <>

                                            {/* DIVISOR PARA DATOS DEL CLIENTE */}

                                            <Grid item xs={12}>

                                                <Divider>Datos Cliente</Divider>

                                            </Grid>

                                            {/* NOMBRE DEL CLIENTE */}

                                            <Grid item md={6} xs={12}>

                                                <TextInput
                                                    name="nombre"
                                                    label="Nombre Cliente"
                                                />

                                            </Grid>

                                            {/* TELEFONO DEL CLIENTE */}

                                            <Grid item md={6} xs={12}>

                                                <MyPhoneInput
                                                    name="telefono"
                                                    label="Telefono"
                                                />

                                            </Grid>

                                            {/* DIVISOR PARA DATOS DEL PAGO */}

                                            <Grid item xs={12}>

                                                <Divider>Datos De Pago</Divider>

                                            </Grid>

                                            {/* MONTO EFECTIVO */}

                                            <Grid item md={6} xs={12}>

                                                <MyMoneyInput
                                                    name="monto_efectivo"
                                                    label="Efectivo"
                                                />

                                            </Grid>

                                            {/* MONTO TARJETA */}

                                            <Grid item md={6} xs={12}>

                                                <MyMoneyInput
                                                    name="monto_tarjeta"
                                                    label="Tarjeta"
                                                />

                                            </Grid>

                                            {/* ALERTA PARA WARNINGS */}

                                            {
                                                warning.show && (

                                                    <Grid item xs={12}>

                                                        <Alert severity="warning">{warning.message}</Alert>

                                                    </Grid>

                                                )
                                            }

                                            {/* RESUMEN DE LOS VESTIDOS */}

                                            <Grid item xs={12}>

                                                <Accordion>
                                                    
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel2-content"
                                                        id="panel2-header"
                                                    >
                                                        Total - ${total.toFixed(2)}
                                                    </AccordionSummary>

                                                    <AccordionDetails>

                                                        <Table aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="center">Precio</TableCell>
                                                                    <TableCell align="center">Talla</TableCell>
                                                                    <TableCell align="center">Evento</TableCell>
                                                                    <TableCell align="center">Entrega</TableCell>
                                                                    <TableCell align="center">Devolucion</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {vestidos.map((row, index) => (
                                                                    <TableRow
                                                                        key={index}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell align="center">{row.precio}</TableCell>
                                                                        <TableCell align="center">{row.talla}</TableCell>
                                                                        <TableCell align="center">{row.fecha_evento}</TableCell>
                                                                        <TableCell align="center">{row.fecha_entrega}</TableCell>
                                                                        <TableCell align="center">{row.fecha_devolucion}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>

                                                    </AccordionDetails>

                                                </Accordion>

                                            </Grid>

                                            </>

                                        ) : (

                                            <>

                                            {/* ALERTA SUCCESS */}

                                            <Grid item xs={12}>

                                                <Alert variant="filled" severity="success" sx={{ color: 'white', fontWeight: 'bold', fontSize: 18 }}>
                                                    Renta completada Exitosamente !
                                                </Alert>

                                            </Grid>

                                            {/* BOTON PARA IMPRIMIR RECIBO */}

                                            <Grid item xs={6}>

                                            <Box sx={{ width: '100%', backgroundColor: '#566573', height: 130, borderRadius: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

                                                <IconButton>
                                                    <PrintIcon sx={{ fontSize: 80 }}/>
                                                </IconButton>

                                                <Typography textAlign={'center'} color={'white'} fontWeight={'bold'}>
                                                    Imprimir Recibo
                                                </Typography>

                                            </Box>

                                            </Grid>

                                            {/* BOTON PARA IMPRIMIR CONTRATO */}

                                            <Grid item xs={6}>

                                                <Box sx={{ width: '100%', backgroundColor: '#566573', height: 130, borderRadius: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

                                                    <IconButton>
                                                        <AssignmentIcon sx={{ fontSize: 80 }}/>
                                                    </IconButton>

                                                    <Typography textAlign={'center'} color={'white'} fontWeight={'bold'}>
                                                        Imprimir Contrato
                                                    </Typography>

                                                </Box>

                                            </Grid>

                                            {/* BOTON PARA CERRAR MODAL */}

                                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>

                                                <Button 
                                                    variant="contained" 
                                                    color="primary" 
                                                    onClick={onClose}
                                                >
                                                    Cerrar
                                                </Button>

                                            </Grid>

                                            </>

                                        )
                                    }

                                </Grid>

                            </ScrollableContainer>

                        {/* FOOTER DEL MODAL */}

                        {
                            showFormulario && (

                                <Box 
                                    sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'space-between', 
                                    }}
                                >

                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={onClose}
                                    >
                                        Cancelar
                                    </Button>

                                    <Button 
                                        type="submit" 
                                        variant="contained" 
                                        sx={{color: "white"}} 
                                        color="success" 
                                        startIcon={<PaidIcon />}
                                    >
                                        Pagar
                                    </Button>

                                </Box>

                            )
                        }

                    </Form>

                </Paper>

            )}
            </Formik>

        </Modal>

    )

}

export default ModalTerminarRenta