import React from 'react';
import fondoQD from "../../assets/img/plantilla_qd/QDBackGround.png";
import logoQD from '../../assets/img/plantilla_qd/QDLogoLogin.png';
import Cookies from 'js-cookie';

// LIBRERIAS

import axios from 'axios';
import {baseURL} from '../../config/url';
import { useNavigate } from 'react-router-dom';
import { useState } from "react";

const Login = ({setShowLogin}) => {

  const navigate = useNavigate();

  // ===============================================
  // ACTUALIZAR VALORES DEL EMIAL Y EL PASSWORD
  // ===============================================

  const [user, setUser] = useState('');
  const [password, setPassword] = useState(''); 

  const handleChange = (event) => {

    const val = event.target.value;
    const name = event.target.name;

    if(name === "user"){

      setUser(val)

    }

    if(name === "password"){

      setPassword(val)

    }

  }

  // ===============================================  
  // FUNCION PARA EL SUBMIT DEL LOGIN
  // =============================================== 

  const handleLogin = () => {

    if(user !== '' && password !== ''){

      axios.post(`${baseURL}api/login`,{

        user: user,
        password: password
  
      })
      .then(result => {
        
        if(result.data.success === false)
        {
          //setErr(false);
        }
        else
        {

          const token = result.data.token;
          // const idUser = result.data.result.idUser;
          // const tipo = result.data.result.tipo;

          const expires = new Date();

          expires.setDate(expires.getDate() + 1);
  
          Cookies.set('login', "1", {expires});
          Cookies.set('token', token, {expires});
          // Cookies.set('menu', modulos, {expires});
          // Cookies.set('idUser', idUser, {expires});
          // Cookies.set('perfil', tipo, {expires});

          setShowLogin(false);
          
          navigate('/dashboard');

        }
  
      })
      .catch(error => {
  
        //setErr(false);
        console.log(error)
  
      })

    }

  };

    return (

        <div 
          className="login-container" 
          style={{
            backgroundImage: `url(${fondoQD})`, 
            backgroundSize: '100% 100%', 
            boxShadow: "5px 10px 8px #888888"
          }}>

          <form autoComplete="off">

            <div className="login-box">

              <div className="logo-container">
                <img src={logoQD} style={{width: "200px"}}/>
              </div>

              <input 
                name="user"
                type="email" 
                placeholder="Correo electrónico" className="input-field" 
                onChange={handleChange}
              />

              <input 
                name="password"
                type="password" 
                placeholder="Contraseña" 
                className="input-field"
                onChange={handleChange} 
              />

              <button className="login-button" type='button' onClick={handleLogin}>Iniciar sesión</button>

              </div>

            </form>

        </div>
        
    )

}

// Estilos CSS

const styles = `
  .login-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Cambiado para móviles */
    justify-content: center;
    height: 100vh;
    padding: 0px 280px 0px 0px;
    transition: padding 0.3s; /* Suaviza el cambio de padding */
  }

  .background-image {
    /* Estilos para la imagen de fondo */
  }

  .login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
  }

  .logo-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-field {
    width: 300px;
    height: 40px;
    margin-bottom: 10px;
    padding: 5px;
  }

  .login-button {
    width: 150px;
    height: 40px;
    background: linear-gradient(to right, #f15f25, #f15f25);
    border-radius: 10px; /* Corregido */
    color: #ffffff;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    .login-container {
      align-items: center;
      padding: 0px 20px; /* Menor padding en pantallas pequeñas */
    }

    .input-field {
      width: 100%; /* Ajusta el ancho de los campos de entrada para pantallas pequeñas */
    }
  }
`;

// Aplicar los estilos al componente
const styleSheet = document.createElement('style');
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Login