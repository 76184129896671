import React, { useEffect, useState } from 'react';
import { Button, Modal, Paper, Typography, Box, Grid, Container, MenuItem} from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import useMediaQuery from "@mui/material/useMediaQuery";
import Swal from 'sweetalert2';
import { styled } from '@mui/system'
import { api, sendFormData } from '../../../../config/axios';

//ICONOS
import SaveIcon from '@mui/icons-material/Save';
import ImageIcon from '@mui/icons-material/Image';

//COMPONENTES
import MySelect from '../../../../components/inputs/MySelect';
import MyMoneyInput from '../../../../components/inputs/MyMoneyInput';
import MyNumberInput from '../../../../components/inputs/MyNumberInput';
import MyInputFile from '../../../../components/inputs/MyInputFile';

const CrearVestido = ({ open, onClose, refresh}) => {

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // VALIDAR SI SE ESTA VIENDO DESDE UN DISPOSITVO MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // FUNCION PARA BUSCAR COLORES
    // ===============================================

    const [colores, setColores] = useState([]);

    const buscarColores = async () => {

        const response = await api.get('colores');

        if(response.data !== "Sin Resultados" && response.status === 200){

            setColores(response.data);

        }

    }

    // ===============================================
    // FUNCION PARA BUSCAR TALLAS
    // ===============================================

    const [tallas, setTallas] = useState([]);

    const buscarTallas = async () => {

        const response = await api.get('tallas');

        if(response.data !== "Sin Resultados" && response.status === 200){

            setTallas(response.data);

        }

    }

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = async (values) => {

        const url = 'vestidos/crear';

        onClose();

        Swal.fire({
            title: 'Guardando vestidos...',
            text: 'Por favor, espere un momento.',
            didOpen: () => {
              Swal.showLoading();
            },
            allowOutsideClick: false,
            allowEscapeKey: false
        });

        const result = await sendFormData(url, values);

        if(result.success === true){

            refresh();

            Swal.fire({
                icon: 'success',
                title: result.message,
            })

        }

    }

    // ===============================================
    // LLAMAR A TODAS LAS FUNCIONES
    // ===============================================

    useEffect(() => {

        buscarColores();

        buscarTallas();

    },[])

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={{ 
                    talla: '',
                    color: '',
                    precio: '',
                    venta_renta: '',
                    disponible: '',
                    cantidad: '',
                    ruta_foto: ''
                }}
                validationSchema={Yup.object().shape({
                    talla: Yup.string().required('Campo Requerido'),
                    color: Yup.string().required('Campo Requerido'),
                    precio: Yup.string().required('Campo Requerido'),
                    venta_renta: Yup.string().required('Campo Requerido'),
                    disponible: Yup.string().required('Campo Requerido'),
                    cantidad: Yup.string().required('Campo Requerido'),
                    ruta_foto: Yup.mixed()
                    .test('fileType', 'Formato de archivo no válido', (value) => {
                    return value && ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type);
                    })
                    .test('fileSize', 'El archivo es demasiado grande', (value) => {
                    return value && value.size <= 10485760; // 10MB
                    })
                })}
                onSubmit={handleSubmit}
            >
            {({ values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting }) => (

                <Paper
                    sx={{ 
                        width: isNonMobile ? `70%` : '85%',
                        maxHeight: isNonMobile ? '60vh' : '80vh',
                        overflow:'auto',
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    {/* HEADER DEL MODAL */}

                    <Typography variant="h3" align="center" marginBottom={3}>
                        Agregar Vestido
                    </Typography>

                    <Form encType='multipart/form-data'>

                        {/* CUERPO DEL MODAL */}

                            <Grid container marginBottom={2}>

                                <Grid item md={7} xs={12} marginTop={2}>

                                    {/* SECCION DEL FORMULARIO */}

                                    <ScrollableContainer>

                                    <Grid container spacing={2} marginTop={1}>

                                        {/* TALLA */}

                                        <Grid item md={6} xs={12}>

                                            <MySelect
                                                label="Talla"
                                                name="talla"
                                            >
                                            {
                                                tallas.map((op, index) => (

                                                    <MenuItem 
                                                        value={op.id} 
                                                        key={index}
                                                    >
                                                        {op.talla}
                                                    </MenuItem>

                                                ))
                                            }
                                            </MySelect>

                                        </Grid>

                                        {/* COLOR */}

                                        <Grid item md={6} xs={12}>

                                            <MySelect
                                                label="Color"
                                                name="color"
                                            >
                                            {
                                                colores.map((op, index) => (

                                                    <MenuItem 
                                                        value={op.id} 
                                                        key={index}
                                                    >
                                                        {op.color}
                                                    </MenuItem>

                                                ))
                                            }
                                            </MySelect>

                                        </Grid>

                                        {/* PRECIO */}

                                        <Grid item md={6} xs={12}>

                                            <MyMoneyInput
                                                label="Precio"
                                                name="precio"
                                            />

                                        </Grid>

                                        {/* COMPRA / VENTA */}

                                        <Grid item md={6} xs={12}>

                                            <MySelect
                                                label="Venta / Renta"
                                                name="venta_renta"
                                            >
                                                <MenuItem value="Renta">Renta</MenuItem>
                                                <MenuItem value="Venta">Venta</MenuItem>
                                            </MySelect>

                                        </Grid>

                                        {/* DISPONIBLE */}

                                        <Grid item md={6} xs={12}>

                                            <MySelect
                                                label="Disponible"
                                                name="disponible"
                                            >
                                                <MenuItem value="1">Si</MenuItem>
                                                <MenuItem value="0">No</MenuItem>
                                            </MySelect>

                                        </Grid>

                                        {/* CANTIDAD */}

                                        <Grid item md={6} xs={12}>

                                            <MyNumberInput
                                                label="Cantidad"
                                                name="cantidad"
                                            />

                                        </Grid>

                                        {/* INPUT PARA SELECCIONAR FOTO */}

                                        <Grid item xs={12}>

                                            <MyInputFile
                                                label="Foto de Vestido"
                                                name="ruta_foto"
                                            />

                                        </Grid>

                                    </Grid>

                                    </ScrollableContainer>

                                </Grid>

                                {/* SECCION DE LA FOTO */}

                                <Grid item md={5} xs={12}>

                                    <Grid container spacing={2} marginLeft={1}>

                                        <ScrollableContainer>

                                        {/* VISTA DE LA IMAGEN SELECCIONADA */}

                                        {
                                            values.ruta_foto !== '' 
                                            ?
                                            <Grid item xs={12}>

                                                <img
                                                    src={URL.createObjectURL(values.ruta_foto)}
                                                    alt="Imagen seleccionada"
                                                    style={{ 
                                                        width: '100%', 
                                                        maxHeight: '100vh', 
                                                        marginTop: '10px' 
                                                    }}
                                                />

                                            </Grid>
                                            :
                                            <Grid item xs={12}>

                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}>

                                                    <ImageIcon
                                                        sx={{ 
                                                            fontSize: 300, 
                                                            maxHeight: '100vh', 
                                                            marginTop: '10px',
                                                        }}
                                                    />

                                                </Box>
                                         
                                            </Grid>
                                        }

                                        </ScrollableContainer>

                                    </Grid>

                                </Grid>

                            </Grid>

                        {/* FOOTER DEL MODAL */}

                        <Box 
                            sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-between', 
                            }}
                        >

                            <Button variant="contained" color="primary" onClick={onClose}>
                                Cancelar
                            </Button>

                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{color: "white"}}
                                color="success" 
                                startIcon={<SaveIcon />}
                                disabled={isSubmitting}
                            >
                                Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            )}
            </Formik>

        </Modal>

    )
  
}

export default CrearVestido