import React from 'react'

const Registros = () => {

    return (

        <div>Registros</div>

    )

}

export default Registros