import React, { useState } from 'react';
import { Box, useTheme, Button, Grid, IconButton, Avatar, Typography, Alert } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { api } from '../../config/axios';
import { tokens } from '../../theme';
import { DataGrid } from '@mui/x-data-grid';
import useMediaQuery from "@mui/material/useMediaQuery";

// ICONOS
import SearchIcon from '@mui/icons-material/Search';
import ImageIcon from '@mui/icons-material/Image';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

// COMPONENTES
import TextInput from '../../components/inputs/TextInput';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import ModalTerminarRenta from './ModalTerminarRenta';
import MyDateInput from '../../components/inputs/MyDateInput';

const OrdenRenta = () => {

    // ===============================================
    // VALIDAR SI SE VE DESDE UN MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // ESTADO PARA VALIDAR ALERTA
    // ===============================================

    const [warning, setWarning] = useState(false);

    // ===============================================
    // ESTADO PARA CARRITO DE COMPRAS
    // ===============================================

    const [carrito, setCarrito] = useState([]);

    // ===============================================
    // ESTADO PARA CONTROL DEL TOTAL
    // ===============================================

    const [totalCarrito, setTotalCarrito] = useState(0)

    // ===============================================
    // ESTADO PARA DATOS DEL VESTIDO
    // ===============================================

    const [datosVestido, setDatosVestido] = useState({

        id: '',
        ruta_foto: '',
        precio: '',
        cantidad: '',
        tallaDescripcion: '',
        colorDescripcion: '',
        comentario: '',
        disponible: ''

    });

    // ===============================================
    // ESTADO PARA MODAL
    // ===============================================

    const [modal, setModal] = useState(false);

    const closeModal = () => {
        setModal(false)
    }

    // ===============================================
    // FUNCION PARA BUSCAR VESTIDO SELECCIONADO
    // ===============================================

    const buscarVestido = async (idVestido, fechaEvento) => {

        const response = await api.get(`vestidos/id/${idVestido}`);

        if(response.data !== "Sin Resultados" && response.status === 200){

            setWarning(false);

            let data = response.data[0];

            const rutaCompleta = data.ruta_foto.split('/');

            const ultimoIndice = rutaCompleta.length - 1;

            const path = rutaCompleta[ultimoIndice].split('%');

            const finalPath = `http://localhost:5000/fotos/vestidos/${path[0]}%25${path[1]}`;

            data.ruta_foto = finalPath; 

            setDatosVestido(data);

        }else{

            setWarning(true);

            setDatosVestido({

                id: '',
                ruta_foto: '',
                precio: '',
                cantidad: '',
                tallaDescripcion: '',
                colorDescripcion: '',
                comentario: '',
                disponible: ''
        
            })

        }

    }

    // ===============================================
    // FUNCION PARA AGREGAR VESTIDO AL CARRITO
    // ===============================================

    const agregarVestido = (comentario, evento, entrega, devolucion) => {

        // ACTUALIZAMOS CARRITO

        const vestidoSeleccionado = {

            id: datosVestido.id,
            foto: datosVestido.ruta_foto,
            precio: datosVestido.precio,
            color: datosVestido.colorDescripcion,
            talla: datosVestido.tallaDescripcion,
            fecha_evento: evento,
            fecha_entrega: entrega,
            fecha_devolucion: devolucion,
            comentario: comentario

        }

        const dataCarrito = [...carrito, vestidoSeleccionado];

        setCarrito(dataCarrito);

        // ACTUALIZAMOS TOTAL

        const totalActual = totalCarrito;

        const formatoNumero = datosVestido.precio.replace(/[^0-9.]/g, '');

        const totalFormateado = parseFloat(formatoNumero);
        
        setTotalCarrito(totalActual + totalFormateado)
        
    }

    // ===============================================
    // FUNCION PARA ELIMINAR VESTIDO SELECCIONADO
    // ===============================================

    const deleteVestido = (idVestido) => {

        // ACTUALIZAMOS CARRITO

        let datosCarrito = carrito;

        const filter = datosCarrito.filter(obj => obj.id !== idVestido);

        setCarrito(filter);

        // ACTUALIZAMOS TOTAL DEL CARRITO

        const filterTotal = datosCarrito.filter(obj => obj.id === idVestido)[0]['precio'];

        const totalActual = totalCarrito;

        const formatoNumero = filterTotal.replace(/[^0-9.]/g, '');

        const totalFormateado = parseFloat(formatoNumero);
        
        setTotalCarrito(totalActual - totalFormateado)

    }
    
    // ===============================================
    // COLUMNAS PARA LA TABLA DEL CARRITO
    // ===============================================

    const columns = [

        { 
            field: 'foto', 
            headerName: 'Vestido', 
            headerAlign: "center",
            align: "center",
            flex: 1,
            renderCell: (params) =>
                <Avatar
                    alt="Foto Vestido"
                    src={params.value}
                    sx={{ width: 70, height: 70 }}
                />
        },
        { 
            field: 'id', 
            headerName: 'Codigo', 
            headerAlign: "center",
            align: "center",
            flex: 1,
        },
        { 
            field: 'precio', 
            headerName: 'Precio', 
            headerAlign: "center",
            align: "center",
            flex: 1,
        },
        { 
            field: 'color', 
            headerName: 'Color', 
            headerAlign: "center",
            align: "center",
            flex: 1,
        },
        { 
            field: 'talla', 
            headerName: 'Talla', 
            headerAlign: "center",
            align: "center",
            flex: 1,
        },
        { 
            field: 'D', 
            headerName: '', 
            headerAlign: "center",
            align: "center",
            flex: 1,
            renderCell: (params) =>
                <Button 
                    variant="contained" 
                    color="error"
                    onClick={() => deleteVestido(params.row.id)}
                    key={`delete-${params.row.id}`}
                >
                    <DeleteIcon/>
                </Button>
        },

    ]; 

    // ===============================================
    // FUNCION PARA CALCULAR FECHA DE ENTREGA Y FECHA DE DEVOLUCION
    // ===============================================

    const calcularFechas = (fechaEvento) => {

        // Fecha original
        let fechaOriginal = new Date(fechaEvento);

        // Fecha del día siguiente
        let fechaSiguiente = new Date(fechaOriginal);

        // Verificar si la fecha original es un sábado
        if (fechaOriginal.getDay() === 5) { // 6 representa el sábado en JavaScript
            // Si es sábado, sumar 2 días
            fechaSiguiente.setDate(fechaOriginal.getDate() + 2);
        } else {
            // Si no es sábado, sumar 1 día
            fechaSiguiente.setDate(fechaOriginal.getDate() + 1);
        }

        // Fecha del día anterior
        let fechaAnterior = new Date(fechaOriginal);
        fechaAnterior.setDate(fechaOriginal.getDate() - 1);

        const fechas = {
            fechaEntrega: fechaAnterior.toISOString().split('T')[0],
            fechaDevolucion: fechaSiguiente.toISOString().split('T')[0]
        }

        return fechas;

    }

    // ===============================================

    return (

        <Box m="20px">

            <Formik
                initialValues={{ 
                    seleccionVestido: '',
                    comentarios: '',
                    fecha_evento: '',
                    fecha_entrega: '',
                    fecha_devolucion: ''
                }}
                validationSchema={Yup.object().shape({
                    seleccionVestido: Yup.string().required('Seleccione un vestido'),
                    fecha_evento: Yup.string().required('Seleccione un vestido'),
                })}
            >
            {({ values, setFieldValue }) => (

                <Form>

                    <Grid container spacing={1}>

                        {/* SECCION PARA DATOS DEL CLIENTE */}

                        <Grid 
                            item 
                            md={5} 
                            xs={12} 
                            sx={{ 
                                border: '1px solid #ffffff', 
                                p: '20px',
                                borderRadius: '10px'
                            }}
                        >

                            <Grid container spacing={2}>

                                {/* SELECCION DE VESTIDOS */}

                                <Grid item xs={12}>

                                    <Typography textAlign={'center'} variant='h2' fontWeight={'bold'}>
                                        Buscar Vestidos
                                    </Typography>

                                </Grid>

                                {/* INPUT PARA BUSCAR VESTIDO */}

                                <Grid item xs={6}>

                                    <TextInput
                                        name="seleccionVestido"
                                        label="Ingrese Codigo Del Vestido"
                                    />

                                </Grid>

                                {/* INPUT PARA FECHA DE EVENTO */}

                                <Grid item xs={5}>

                                    <MyDateInput
                                        name="fecha_evento"
                                        label="Fecha Evento"
                                    />

                                </Grid>

                                {/* BOTON PARA BUSCAR VESTIDO */}

                                <Grid item xs={1}>

                                    <IconButton 
                                        type="button" 
                                        onClick={ async () => {

                                            // BUSCAMOS DATOS DEL VESTIDO

                                            buscarVestido(values.seleccionVestido, values.fecha_evento.split('T')[0]);

                                            setFieldValue('comentarios', '');

                                            // CALCULAMOS LAS FECHAS

                                            const fechas = await calcularFechas(values.fecha_evento.split('T')[0]);

                                            setFieldValue('fecha_entrega', fechas.fechaEntrega);

                                            setFieldValue('fecha_devolucion', fechas.fechaDevolucion);

                                        }}
                                    >
                                        <SearchIcon sx={{fontSize: 30}}/>
                                    </IconButton>

                                </Grid>

                                {/* SECCION PARA ALERTA */}

                                {
                                    warning && (

                                        <Grid item xs={12}>

                                            <Alert severity="warning">No existe el vestido seleccionado !</Alert>

                                        </Grid>

                                    )
                                }

                                {/* SECCION PARA VER INFO DEL VESTIDO SELECCIONADO */}

                                <Grid item xs={12}>

                                    <Grid container spacing={2}>

                                        {/* FOTO DEL VESTIDO */}

                                        <Grid 
                                            item 
                                            md={6} 
                                            xs={12} 
                                            sx={{
                                                display: 'flex', 
                                                justifyContent: 'center'
                                            }}
                                        >

                                        {
                                            datosVestido.ruta_foto !== ''
                                            ?
                                            (
                                                <img
                                                    src={datosVestido.ruta_foto}
                                                    alt="Imagen seleccionada"
                                                    style={{ 
                                                        width: '100%', 
                                                        height: '350px', 
                                                        marginTop: '10px' 
                                                    }}
                                                />
                                            )
                                            :
                                            (
                        
                                                <ImageIcon
                                                    sx={{ 
                                                        fontSize: 230, 
                                                        maxHeight: '100vh', 
                                                        marginTop: '10px',
                                                    }}
                                                />
                            
                                            )
                                        }

                                        </Grid>

                                        {/* DATOS DEL VESTIDO */}

                                        <Grid 
                                            item 
                                            md={6} 
                                            xs={12} 
                                            sx={{ 
                                                display: 'flex', 
                                                flexDirection: 'column', 
                                                justifyContent: 'space-around'
                                            }}
                                        >

                                            <Typography variant='h2' fontWeight={'bold'}>
                                                {datosVestido.disponible === 1 ? 'Disponible' : 'Ocupado'}
                                            </Typography>

                                            <Typography variant='h4'>
                                                Precio: <span style={{ fontSize: '30px'}}>{datosVestido.precio}</span>
                                            </Typography>

                                            <Typography variant='h4'>
                                                Color: <span style={{ fontSize: '30px'}}>{datosVestido.colorDescripcion}</span>
                                            </Typography>

                                            <Typography variant='h4'>
                                                Talla: <span style={{ fontSize: '30px'}}>{datosVestido.tallaDescripcion}</span>
                                            </Typography>

                                        </Grid>

                                        {/* TEXT AREA PARA COMENTARIO DEL VESTIDO */}

                                        <Grid item xs={12}>

                                            <TextAreaInput
                                                name="comentarios"
                                                label="Comentarios (Opcional)"
                                            />

                                        </Grid>

                                        {/* FECHA ENTREGA */}

                                        <Grid item xs={6}>

                                            <MyDateInput
                                                name="fecha_entrega"
                                                label="Fecha Entrega"
                                            />

                                        </Grid>

                                        {/* FECHA DEVOLUCION */}

                                        <Grid item xs={6}>

                                            <MyDateInput
                                                name="fecha_devolucion"
                                                label="Fecha Devolucion"
                                            />

                                        </Grid>

                                        {/* BOTON PARA AGREGAR VESTIDO AL CARRITO */}

                                        <Grid item xs={12}>

                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                sx={{
                                                    fontWeight: 'bold', 
                                                    backgroundColor: colors.orangeAccent[100],
                                                    width: '100%'
                                                }}
                                                startIcon={<AddCircleIcon/>}
                                                onClick={() => {

                                                    // AGREGAMOS EL VESTIDO

                                                    const fechaEvento = values.fecha_evento.includes('T') 
                                                    ? 
                                                    values.fecha_evento.split('T')[0] 
                                                    : 
                                                    values.fecha_evento;

                                                    const fechaEntrega = values.fecha_entrega.includes('T') 
                                                    ? 
                                                    values.fecha_entrega.split('T')[0] 
                                                    : 
                                                    values.fecha_entrega;

                                                    const fechaDevolucion = values.fecha_devolucion.includes('T') 
                                                    ? 
                                                    values.fecha_devolucion.split('T')[0] 
                                                    : 
                                                    values.fecha_devolucion;

                                                    agregarVestido(
                                                        values.comentarios,
                                                        fechaEvento,
                                                        fechaEntrega,
                                                        fechaDevolucion
                                                    );

                                                    // LIMPIAMOS LOS VALORES

                                                    setDatosVestido({

                                                        id: '',
                                                        ruta_foto: '',
                                                        precio: '',
                                                        cantidad: '',
                                                        tallaDescripcion: '',
                                                        colorDescripcion: '',
                                                        comentario: '',
                                                        disponible: ''
                                                
                                                    })

                                                    setFieldValue('comentarios', '');

                                                }}
                                                disabled={datosVestido.disponible !== 1}
                                            >
                                                Agregar Vestido
                                            </Button>

                                        </Grid>

                                    </Grid>

                                </Grid>

                            </Grid>

                        </Grid>

                        {/* =============================================================== */}

                        {/* SECCION PARA VENTA DE VESTIDOS */}

                        <Grid 
                            item 
                            md={7}
                            xs={12} 

                        >

                            <Grid 
                                container 
                                spacing={2} 
                                sx={{
                                    paddingLeft: isNonMobile ? '40px' : '0px',
                                    marginTop: isNonMobile ? '0px' : '10px'
                                }}
                            >

                                {/* HEADER */}

                                <Grid item xs={12}>

                                    <Typography textAlign={'center'} variant='h2' fontWeight={'bold'}>
                                        Venta
                                    </Typography>

                                </Grid>

                                {/* TABLA PARA CARRITO */}

                                <Grid item xs={12} >

                                    <DataGrid
                                        rows={carrito}
                                        columns={columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 3 },
                                            },
                                        }}
                                        pageSizeOptions={[3, 15]}
                                        rowHeight={100}
                                    />

                                </Grid> 

                                {/* TOTAL DEL CARRITO */}

                                <Grid item xs={12}>

                                    <Typography 
                                        textAlign={'center'} 
                                        variant='h1' 
                                        fontWeight={'bold'}
                                    >
                                        <span style={{fontSize: '20px'}}>Total: </span>
                                        ${totalCarrito.toFixed(2)}
                                    </Typography>

                                </Grid>

                                {/* BOTON PARA PROCEDER CON LA COMPRA */}

                                <Grid item xs={12}>

                                    <Button 
                                        variant="contained" 
                                        color="success" 
                                        sx={{
                                            fontWeight: 'bold', 
                                            color: 'white',
                                            width: '100%'
                                        }}
                                        startIcon={<MonetizationOnIcon/>}
                                        onClick={() => setModal(true)}
                                    >
                                        Continuar Con Pago
                                    </Button>

                                </Grid>

                            </Grid>
                            
                        </Grid>

                    </Grid>

                </Form>

            )}
            </Formik>

            {/* MODAL PARA TERMINAR RENTA */}

            <ModalTerminarRenta
                open={modal}
                onClose={closeModal}
                vestidos={carrito}
                total={totalCarrito}
            />

        </Box>

    )
  
}

export default OrdenRenta