import React from 'react'

const Agenda = () => {

    return (

        <div>Agenda</div>

    )

}

export default Agenda