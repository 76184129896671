import React from 'react';
import { Button, Modal, Paper, Typography, Box, Grid  } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import useMediaQuery from "@mui/material/useMediaQuery";
import Swal from 'sweetalert2';
import { api } from '../../../../config/axios';

//COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';

//ICONOS
import SaveIcon from '@mui/icons-material/Save';

const CrearTalla = ({ open, onClose, refresh }) => {

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // VALIDAR SI SE ESTA VIENDO DESDE UN DISPOSITVO MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        const url = 'tallas/crear';

        api.post(url, values)
        .then((response) => {

            if(response.data.success === true){

                onClose();

                refresh();

                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={{ 
                    talla: '',
                }}
                validationSchema={Yup.object().shape({
                    talla: Yup.string().required('Campo Requerido')
                })}
                onSubmit={handleSubmit}
            >

                {({ isSubmitting }) => (

                <Paper
                    sx={{ 
                        width: isNonMobile ? `30%` : '90%', 
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    {/* HEADER DEL MODAL */}

                    <Typography variant="h4" align="center" mb={2}>
                        Agregar Talla
                    </Typography>

                    {/* FORMULARIO */}

                    <Form>

                        <Grid container spacing={2}>

                            {/* CAMPO DEL COLOR */}

                            <Grid item xs={12}>

                                <TextInput
                                    label='Talla De Vestido'
                                    name='talla'
                                />

                            </Grid>

                        </Grid>

                        <Box 
                            sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-between', 
                                mt: 2 
                            }}
                        >

                            <Button 
                                type="button" 
                                variant="contained" 
                                color="primary" 
                                onClick={onClose}
                            >
                                Cancelar
                            </Button>

                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{color: "white"}} 
                                color="success" 
                                startIcon={<SaveIcon />}
                                disabled={isSubmitting}
                            >
                                Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

                )}

            </Formik>

        </Modal>

    )

}

export default CrearTalla