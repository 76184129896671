import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import {api} from '../../../config/axios';
import * as Yup from 'yup';
import { marcarURL } from '../../../config/functions';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../../components/Header';
import ModalCreateGM from '../../../components/modals/ModalCreateGM';
import ModalEditGM from '../../../components/modals/ModalEditGM';
import CrearUsuario from './modales/CrearUsuario';


const Usuarios = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // SEGMENTO PARA BUSCAR SUCURSALES
    // ===============================================

    const [data, setData] = useState([]);

    useEffect(() => {

        window.scrollTo(0, 0);

        buscarUsuarios();

        marcarURL('usuarios');

    },[])

    // FUNCION PARA BUSCAR USUARIOS

    const buscarUsuarios = async () => {

        const response = await api.get('get/usuarios');

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            setData(response.data.result);console.log(response.data.result)

        }else{

            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "nombres",
            headerName: "Nombre",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "apellidos",
            headerName: "Apellido",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "email",
            headerName: "Correo",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "nombre_perfil",
            headerName: "Perfil",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "estado",
            headerName: "",
            flex: 2,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => 
                params.value === 1 
                ?
                <Button variant="contained" color="success" sx={{color: "white"}}><b>Activo</b></Button>
                :
                <Button variant="contained" color="error"><b>Baja</b></Button>
        },

    ];

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({

        nombres: '',
        apellidos: '',
        email: '',
        password: '',
        rel_perfil: '',

    })

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {

        setInicialFormValues({

            nombres: '',
            apellidos: '',
            email: '',
            password: '',
            rel_perfil: '',
    
        })

        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL EDITAR
    // ===============================================

    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const handleOpenModalEdit = () => {
        setIsOpenEdit(true);
    };
    
    const handleCloseModalEdit = () => {
        setIsOpenEdit(false);
    };

    // ===============================================
    // SEGMENTO PARA TRAER PERFILES
    // ===============================================

    const [perfiles, setPerfiles] = useState([]);

    useEffect(() => {
        
        api.get('get/perfiles')
        .then(result => {
    
            if(result.data.success === true)
            {
                setPerfiles(result.data.result);
            }
    
        })
        .catch(error => {
        
            console.log(error)
        
        })
    
    },[]);

    // ===============================================
    // INPUTS PARA ARMAR EL FORMULARIO
    // ===============================================

    const inputForms = [

        {
            componente: "input",
            label: "Nombre(s)",
            name: "nombres",
            placeholder: "Ingrese nombre(s)",
            md: 6,
            xs: 12
        },
        {
            componente: "input",
            label: "Apellido(s)",
            name: "apellidos",
            placeholder: "Ingrese apellido(s)",
            md: 6,
            xs: 12
        },
        {
            componente: "input",
            label: "Correo",
            name: "email",
            placeholder: "Ingrese correo",
            md: 6,
            xs: 12
        },
        {
            componente: "password",
            label: "Contraseña",
            name: "password",
            md: 6,
            xs: 12
        },
        {
            componente: "select",
            label: "Perfiles",
            name: "rel_perfil",
            options: perfiles,
            value: 'id',
            text: 'nombre_perfil',
            md: 6,
            xs: 12
        },

    ];

    // ===============================================
    // FUNCION PARA VALIDAR USUARIO
    // ===============================================

    const validarUsuario = async (email) => {

        const response = await api.get(`get/existe/usuario/${email}`);

        if(response.data.result === "Sin resultados"){

            return true;

        }else{

            return false;

        }

    }

    // ===============================================
    // VALIDACIONES PARA EL FORMULARIO
    // ===============================================

    const yupValidation = Yup.object().shape({

        nombres: Yup.string().required('Requerido'),
        apellidos: Yup.string().required('Requerido'),
        email: Yup.string().email('Formato Invalido').required('Requerido')
        .test('validar-empleado', 'Este usuario ya existe', async (value) => {
            const isValid = await validarUsuario(value)
            return isValid;
        }),
        password: Yup.string().required('Requerido'),
        rel_perfil: Yup.number().required('Requerido'),

    })

    // ===============================================
    // VALIDACIONES PARA EL FORMULARIO PARA EDITAR
    // ===============================================

    const yupValidationEdit = Yup.object().shape({

        nombres: Yup.string().required('Requerido'),
        apellidos: Yup.string().required('Requerido'),
        email: Yup.string().email('Formato Invalido').required('Requerido'),
        rel_perfil: Yup.number().required('Requerido'),

    })

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const handleRowClick = (params) => {

        setInicialFormValues(params.row);
        handleOpenModalEdit();

    }

    // ===============================================
    // ===============================================

    return (

        <Box m="20px">

            <Header title="Usuarios" subtitle="Usuarios EBTSuite" />

            {/* BOTON PARA ABRIR MODAL */}

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.orangeAccent[100]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar Usuario
            </Button>

            {/* TABLA  */}

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.orangeAccent[100],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.orangeAccent[100],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.orangeAccent[100],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.orangeAccent[100]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
                }}
            >
                <DataGrid 
                    
                    rows={data} 
                    columns={columns} 
                    components={{ Toolbar: GridToolbar}}
                    onRowClick={handleRowClick}
                />
            </Box>

            {/* MODAL PARA CREAR EMPRESAS */}

            <CrearUsuario
                open={isOpen} 
                onClose={handleCloseModal} 
                refresh={buscarUsuarios}
            />

            {/* <ModalCreateGM
                modalTitle="Crear Usuario"
                swalTitle="Se ha guardado el usuario!"
                url="create/usuario"
                open={isOpen} 
                onClose={handleCloseModal} 
                size='50'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarUsuarios}
            /> */}

            {/* MODAL PARA EDITAR EMPRESAS */}

            <ModalEditGM
                modalTitle="Editar Usuario"
                swalTitle="Se ha editado el usuario!"
                url="update/usuario"
                urlDelete="delete/usuario/"
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                openModal={handleOpenModalEdit}
                size='50'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidationEdit}
                refresh={buscarUsuarios}
            />

        </Box>

    )

}

export default Usuarios