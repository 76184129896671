import React from 'react'

const Venta = () => {

    return (

        <div>Venta</div>

    )

}

export default Venta