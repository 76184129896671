import React, { useEffect, useState } from 'react';
import { Button, Modal, Paper, Typography, Box, Container, Grid  } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import {api} from '../../../../config/axios';
import Swal from 'sweetalert2';
import { styled } from '@mui/system'
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';
import TextInput from '../../../../components/inputs/TextInput';
import MyInputPassword from '../../../../components/inputs/MyInputPassword';
import MySelect from '../../../../components/inputs/MySelect';

const CrearUsuario = ({open, onClose, refresh}) => {

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // VALIDAR SI SE VE DESDE UN MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // SEGMENTO PARA TRAER OPERADORES
    // ===============================================

    const [operadores, setOperadores] = useState([]);

    const buscarOperadores = () => {

        api.get(`get/operadores/select`)
        .then(result => {
    
            if(result.data.success === true && result.data.result !== "Sin resultados")
            {
                let operador = result.data.result;
                operador.unshift({id: 0, operador: 'Seleccione un operador'})
                setOperadores(operador);
            }else{
                setOperadores([{id: 0, operador: 'Sin Operadores'}]);
            }
    
        })
        .catch(error => {
        
            console.log(error)
        
        })

    }

    // ===============================================
    // SEGMENTO PARA TRAER PERFILES
    // ===============================================

    const [perfiles, setPerfiles] = useState([]);

    const buscarPerfiles = () => {

        api.get('get/perfiles')
        .then(result => {
    
            if(result.data.success === true)
            {
                setPerfiles(result.data.result);
            }
    
        })
        .catch(error => {
        
            console.log(error)
        
        })

    }

    // ===============================================
    // FUNCION PARA VALIDAR USUARIO
    // ===============================================

    const validarUsuario = async (email) => {

        const response = await api.get(`get/existe/usuario/${email}`);

        if(response.data.result === "Sin resultados"){

            return true;

        }else{

            return false;

        }

    }

    // ===============================================
    // LLAMA A TODAS LAS OPCIONES DE SELECT
    // ===============================================

    useEffect(() => {

        buscarPerfiles();

        buscarOperadores();

    },[]);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        const url = 'create/usuario';

        api.post(url, values)
        .then((response) => {

            if(response.data.success === true){

                onClose();

                refresh();

                Swal.fire({
                    icon: 'success',
                    title: 'Se ha guardado el usuario!',
                })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    const [showOperador, setShowOperador] = useState(false);

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >
            <Formik
                initialValues={{
                    nombres: '',
                    apellidos: '',
                    email: '',
                    password: '',
                    rel_perfil: '',
                    rel_operador: 0
                }}
                validationSchema={Yup.object({
                    nombres: Yup.string().required('Requerido'),
                    apellidos: Yup.string().required('Requerido'),
                    email: Yup.string().required('Requerido')
                    .test('validar-empleado', 'Este usuario ya existe', async (value) => {
                        const isValid = await validarUsuario(value)
                        return isValid;
                    }),
                    password: Yup.string().required('Requerido'),
                    rel_perfil: Yup.string().required('Requerido'),
                    rel_operador: Yup.string().required('Requerido'),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values)
                }}
            >
            {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (

                <Paper 
                    sx={{ 
                        width: isNonMobile ? `50%` : '90%', 
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    <Form>

                        {/* HEADER DEL MODAL */}

                        <Typography variant="h3" align="center">
                            Crear Usuario
                        </Typography>

                        {/* BODY DEL MODAL */}

                        <ScrollableContainer>

                            <Grid 
                                container 
                                spacing={2} 
                                paddingBottom={2} 
                                marginTop={2}
                            >

                                {/* NOMBRE(S) */}

                                <Grid item xs={6}>

                                    <TextInput
                                        label='Nombre(s)' 
                                        name='nombres'
                                        placeholder='Ingrese nombre(s)'
                                    />

                                </Grid>

                                {/* APELLIDO(S) */}

                                <Grid item xs={6}>

                                    <TextInput
                                        label='Apellido(s)' 
                                        name='apellidos'
                                        placeholder='Ingrese apellido(s)'
                                    />

                                </Grid>

                                {/* USUARIO */}

                                <Grid item xs={6}>

                                    <TextInput
                                        label='Usuario' 
                                        name='email'
                                        placeholder='Ingrese usuario'
                                    />

                                </Grid>

                                {/* PASSWORD */}

                                <Grid item xs={6}>

                                    <MyInputPassword
                                        label='Contraseña' 
                                        name='password'
                                    />

                                </Grid>

                                {/* PERFIL */}

                                <Grid item xs={6}>

                                    <MySelect
                                        label='Perfil' 
                                        name='rel_perfil'
                                        onChange={(event) => {
                                            handleChange(event);
                                            event.target.value === 2 
                                            ?
                                            setShowOperador(true) 
                                            :
                                            setShowOperador(false)
                                        }}
                                    >
                                    {
                                        perfiles.map((op) => (

                                            <MenuItem 
                                                value={op.id} 
                                                key={op.id}
                                            >
                                                {op.nombre_perfil}
                                            </MenuItem>
        
                                        ))
                                    }
                                    </MySelect>

                                </Grid>

                                {/* OPERADOR */}

                                {
                                    showOperador ? (

                                    <Grid item xs={6}>

                                        <MySelect
                                            label='Operador' 
                                            name='rel_operador'
                                        >
                                        {
                                            operadores.map((op) => (

                                                <MenuItem 
                                                    value={op.id} 
                                                    key={op.id}
                                                >
                                                    {op.operador}
                                                </MenuItem>

                                            ))
                                        }
                                        </MySelect>

                                    </Grid>

                                    )
                                    :
                                    null
                                }

                                

                            </Grid>

                        </ScrollableContainer>

                        {/* FOOTER DEL MODAL */}

                    <Box 
                        sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                        }}
                    >

                        <Button variant="contained" color="primary" onClick={onClose}>
                            Cancelar
                        </Button>

                        <Button type="submit" variant="contained" sx={{color: "white"}} color="success" startIcon={<SaveIcon />}>
                            Guardar
                        </Button>

                    </Box>

                    </Form>

                </Paper>

            )}
            </Formik>

        </Modal>

    )

}

export default CrearUsuario