import React, {useState, useEffect} from 'react'
import { ColorModeContext, useMode } from './theme';
import { tokens } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Topbar from "./scenes/global/Topbar";
import Dashboard from "./scenes/dashboard";
import Sidebar from "./scenes/global/Sidebar";

// COMPONENTES
import Calendar from './scenes/calendar';

// COMPONENTES QUEEN DRESS

import Login from './pages/login/Login';
import Usuarios from './pages/catalogos/usuarios/Usuarios';
import Colores from './pages/catalogos/colores/Colores';
import Tallas from './pages/catalogos/tallas/Tallas';
import Vestidos from './pages/catalogos/vestidos/Vestidos';
import Renta from './pages/renta/Renta';
import Venta from './pages/venta/Venta';
import Agenda from './pages/agenda/Agenda';
import Registros from './pages/registros/Registros';
import OrdenRenta from './pages/renta/OrdenRenta';

function App() {

  const [theme, colorMode] = useMode();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  // ===============================================  
  // LOGIN
  // =============================================== 

  const [showLogin, setShowLogin] = useState(true);

  useEffect(() => {

    if(Cookies.get('login') === "1"){

      setShowLogin(false);

      const ruta = Cookies.get('modulo');

      navigate(`/${ruta}`);

    }

  },[])

  // ===============================================

  return (

    <ColorModeContext.Provider value={colorMode}>

      <ThemeProvider theme={theme}>

        <CssBaseline>

          <div className='app'>

            {showLogin ? null : <Sidebar />}

            <main 
              className='content' 
              style={{
                backgroundColor: colors.primary[1000], 
                minHeight: '100vh',
                overflow: 'auto'
              }}
            >

              {showLogin ? null : <Topbar setShowLogin={setShowLogin}/>}

              <Routes>

                {/* INICIO */}

                <Route path='/' element={<Login setShowLogin={setShowLogin}/>}/>
                <Route path='/dashboard' element={<Dashboard/>}/>

                {/* CATALOGOS */}

                <Route path='/usuarios' element={<Usuarios/>}/>
                <Route path='/colores' element={<Colores/>}/>
                <Route path='/tallas' element={<Tallas/>}/>
                <Route path='/vestidos' element={<Vestidos/>}/>

                {/* PROCESOS COMPRA / VENTA */}

                <Route path='/renta' element={<OrdenRenta/>}/>
                <Route path='/venta' element={<Venta/>}/>
                <Route path='/agenda' element={<Agenda/>}/>
                <Route path='/registros' element={<Registros/>}/>

                {/* CALENDARIO */}

                <Route path='/calendar' element={<Calendar/>}/>

              </Routes>

            </main>

          </div>

        </CssBaseline>

      </ThemeProvider>

    </ColorModeContext.Provider>

  )
  
}

export default App