import React, {useEffect, useState} from "react";
import { marcarURL } from "../../config/functions";
import DashboardAdmin from "../../pages/catalogos/dashboard/DashboardAdmin";

const Dashboard = () => {

  // ===============================================

  useEffect(() => {

    marcarURL('dashboard');

  },[]);

  // ===============================================

  return (

    <>

      <DashboardAdmin/>

    </>

  );
};

export default Dashboard;