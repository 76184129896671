import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Drawer } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import logoQD from '../../assets/img/plantilla_qd/icoPNG.png';
// import Cookies from "js-cookie";

// ICONOS 
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ColorLensIcon from '@mui/icons-material/ColorLens';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import WomanIcon from '@mui/icons-material/Woman';
import PaidIcon from '@mui/icons-material/Paid';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [isMobile, setIsMobile] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Define una función para verificar si el dispositivo es un móvil
  const checkIsMobile = () => {
    setIsMobile(window.innerWidth < 768); // Puedes ajustar este valor según tus necesidades
  };

  // Registra un listener para verificar si el dispositivo es un móvil cuando la ventana se redimensiona
  useEffect(() => {
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
          paddingRight: isCollapsed ? '0px' : '50px'
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#F15F24 !important",
        },
        "& .pro-menu-item.active": {
          color: "#F15F24 !important",
        },
        minHeight: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {isMobile ? ( // Muestra un botón de menú en lugar del sidebar en dispositivos móviles
        <IconButton
          sx={{
            zIndex: 1,
            position: "absolute",
            top: "20px",
            left: "20px",
            color: colors.grey[100],
          }}
          onClick={toggleSidebar}
        >
          <MenuOutlinedIcon />
        </IconButton>
      ) : (
        <ProSidebar collapsed={isCollapsed} sx={{width: '100%'}}>
          <Menu iconShape="square">
            {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={logoQD}
                  style={{ cursor: "pointer", borderRadius: "10%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  Queen Dress
                </Typography>
                {/* <Typography variant="h5" color={colors.greenAccent[500]}>
                  VP Fancy Admin
                </Typography> */}
              </Box>
            </Box>
          )}

          {/*  */}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>

            {/* DASHBOARD */}

            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* TALLAS */}

            <Item
              title="Tallas"
              to="/tallas"
              icon={<LoyaltyIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* COLORES */}

            <Item
              title="Colores"
              to="/colores"
              icon={<ColorLensIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* VESTIDOS */}

            <Item
              title="Vestidos"
              to="/vestidos"
              icon={<WomanIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* SUBMENU PARA EVENTOS */}

            {/* <SubMenu title="Venta | Renta" icon={<PaidIcon />}> */}

              {/* RENTA DE VESTIDOS */}

              {/* <Item
                title="Renta"
                to="/renta"
                icon={<ArrowRightIcon />}
                selected={selected}
                setSelected={setSelected}
              /> */}

              {/* VENTA DE VESTIDOS */}

              {/* <Item
                title="Venta"
                to="/venta"
                icon={<ArrowRightIcon />}
                selected={selected}
                setSelected={setSelected}
              /> */}

              {/* REGISTROS */}

              {/* <Item
                title="Registros"
                to="/registros"
                icon={<ArrowRightIcon />}
                selected={selected}
                setSelected={setSelected}
              /> */}

              {/* AGENDA */}

              {/* <Item
                title="Agenda"
                to="/agenda"
                icon={<ArrowRightIcon />}
                selected={selected}
                setSelected={setSelected}
              />

            </SubMenu> */}

          </Box>
          </Menu>
        </ProSidebar>
      )}

      {/* --------------------------------------------------------------------- */}

      {isMobile && ( // Muestra el sidebar en un Drawer en dispositivos móviles
        <Drawer
          anchor="left"
          open={isCollapsed}
          onClose={toggleSidebar}
          variant="temporary"
        >
          <ProSidebar collapsed={isCollapsed} sx={{width: '100%'}}>
            <Menu iconShape="square">
              {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={logoQD}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  Queen Dress
                </Typography>
                {/* <Typography variant="h5" color={colors.greenAccent[500]}>
                  VP Fancy Admin
                </Typography> */}
              </Box>
            </Box>
          )}

          {/*  */}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>

            {/* DASHBOARD */}

            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* TALLAS */}

            <Item
              title="Tallas"
              to="/tallas"
              icon={<LoyaltyIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* COLORES */}

            <Item
              title="Colores"
              to="/colores"
              icon={<ColorLensIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* VESTIDOS */}

            <Item
              title="Vestidos"
              to="/vestidos"
              icon={<WomanIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* SUBMENU PARA EVENTOS */}

            {/* <SubMenu title="Venta | Renta" icon={<PaidIcon />}> */}

              {/* RENTA DE VESTIDOS */}

              {/* <Item
                title="Renta"
                to="/renta"
                icon={<ArrowRightIcon />}
                selected={selected}
                setSelected={setSelected}
              /> */}

              {/* VENTA DE VESTIDOS */}

              {/* <Item
                title="Venta"
                to="/venta"
                icon={<ArrowRightIcon />}
                selected={selected}
                setSelected={setSelected}
              /> */}

              {/* REGISTROS */}

              {/* <Item
                title="Registros"
                to="/registros"
                icon={<ArrowRightIcon />}
                selected={selected}
                setSelected={setSelected}
              /> */}

              {/* AGENDA */}

              {/* <Item
                title="Agenda"
                to="/agenda"
                icon={<ArrowRightIcon />}
                selected={selected}
                setSelected={setSelected}
              />

            </SubMenu> */}

          </Box>
            </Menu>
          </ProSidebar>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
