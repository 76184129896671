import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import {api} from '../../../config/axios';
import { marcarURL } from '../../../config/functions';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../../components/Header';
import CrearColor from './modales/CrearColor';
import EditarColor from './modales/EditarColor';

const Colores = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // SEGMENTO PARA BUSCAR CAJAS
    // ===============================================

    useEffect(() => {

        window.scrollTo(0, 0);

        buscarColores();

        marcarURL('colores');

    },[]);

    // ===============================================
    // FUNCION PARA BUSCAR COLORES
    // ===============================================

    const [data, setData] = useState([]);

    const buscarColores = async () => {

        const response = await api.get('colores');

        if(response.data !== "Sin Resultados" && response.status === 200){

            setData(response.data);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "color",
            headerName: "Color",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },

    ];

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREAR
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {
        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL EDITAR
    // ===============================================

    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const handleOpenModalEdit = () => {
        setIsOpenEdit(true);
    };
    
    const handleCloseModalEdit = () => {
        setIsOpenEdit(false);
    };

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const [formValues, setFormValues] = useState({})

    const handleRowClick = (params) => {

        setFormValues(params.row);
        handleOpenModalEdit();

    }

    // ===============================================

    return (

        <Box m="20px">

            <Header title="Colores" subtitle="Catalago de Colores Para Vestidos" />

            {/* BOTON PARA ABRIR EL MODAL */}

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.orangeAccent[100]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar Color
            </Button>

            {/* TABLA  */}

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.orangeAccent[100],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.orangeAccent[100],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.orangeAccent[100],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.orangeAccent[100]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
                }}
            >
                <DataGrid 
                    rows={data} 
                    columns={columns} 
                    components={{ Toolbar: GridToolbar}}
                    onRowClick={handleRowClick}
                    autoHeight
                />
            </Box>

            {/* MODAL PARA CREAR */}

            <CrearColor
                open={isOpen} 
                onClose={handleCloseModal} 
                refresh={buscarColores}
            />

            {/* MODAL PARA EDITAR */}

            <EditarColor
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                refresh={buscarColores}
                data={formValues}
            />

        </Box>

    )

}

export default Colores
