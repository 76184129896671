import React from 'react';
import { useField } from 'formik';
import { TextField } from "@mui/material";
import Swal from 'sweetalert2';

const MyInputFile = ({ label, accept, ...props }) => {

    const [field, meta, helpers] = useField(props);

    const handleChange = (event) => {
        const file = event.currentTarget.files[0];
        helpers.setValue(file);
    };

    return (

        <TextField
            sx={{ width: "100%" }}
            variant="outlined"
            className="file-input"
            label={label}
            type="file"
            inputProps={{ accept: props.accept || '.jpg, .jpeg, .png, .gif, .pdf' }}
            onChange={handleChange}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
        />

    )

}

export default MyInputFile